import { AnalyticsBrowser } from '@segment/analytics-next';
import { ENV } from '../config/environment';
import { browserNameAndVersion } from '../utils/browser';
import { AuthState, authState, speechifyMobileUser } from '../stores/user';
import { get } from 'svelte/store';
import type {
  CorePlugin,
  EventProperties,
  PluginType,
} from '@segment/analytics-core';

const getCookieValue = (name: string): string | undefined =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ??
  undefined;

const CookieEnricher: CorePlugin = {
  name: 'Cookie Enricher',
  version: '1.0.0',
  type: 'enrichment' as PluginType,

  isLoaded: () => {
    return true;
  },

  /**
   * Load ad click ids from url, fallback to cookies and localStorage.
   * Then persist the clicks in the localstorage, accessible for the segment.track() event.
   * This approach implements last touch attribution.
   */
  load() {
    // parse URL for utm params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Google and Meta click ids
    const gclid =
      urlParams.get('gclid') ?? localStorage.getItem('seg_gclid') ?? '';
    const fbclid = urlParams.get('fbclid') ?? '';
    const fbPrefix = `fb.1.${Date.now()}`;
    // We rely on the FB Pixel to update the cookies automatically every time it registers a new cookie.
    const fbc =
      getCookieValue('_fbc') ??
      localStorage.getItem('seg_fbc') ??
      `${fbPrefix}.${fbclid}`;
    const fbp =
      getCookieValue('_fbp') ??
      localStorage.getItem('seg_fbp') ??
      `${fbPrefix}.${Math.random().toString().split('.')[1].substring(10)}`;

    localStorage.setItem('seg_gclid', gclid);
    localStorage.setItem('seg_fbp', fbp);
    localStorage.setItem('seg_fbc', fbc);

    return Promise.resolve();
  },

  track(ctx) {
    if (!ctx.event.properties) {
      ctx.event.properties = {} as EventProperties;
    }
    ctx.event.properties.gclid = localStorage.getItem('seg_gclid');
    ctx.event.properties.fbc = localStorage.getItem('seg_fbc');
    ctx.event.properties.fbp = localStorage.getItem('seg_fbp');
    ctx.event.properties.email = get(speechifyMobileUser)?.email;

    return ctx;
  },
};

export class AnalyticsService {
  private static prefix = 'dubbing_';
  static readonly segment: AnalyticsBrowser = AnalyticsService.getInstance();

  private static getInstance(): AnalyticsBrowser {
    const segment = AnalyticsBrowser.load(
      {
        writeKey: ENV.segmentWriteKey,
        cdnURL: ENV.segmentProxyCdnHost,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: ENV.segmentProxyApiHost,
            deliveryStrategy: {
              config: {
                keepalive: true,
              },
            },
          },
        },
      },
    );
    void segment.register(CookieEnricher);
    return segment;
  }

  static getBrowserDetails() {
    const [browserName, browserVersion] = browserNameAndVersion('/').split('/');
    return {
      browserName,
      browserVersion,
      webpage: window.location.href,
      hostname: window.location.hostname,
    };
  }

  // We don't want to block the user from using the app if the analytics event fails
  // thus please don't await this function
  static async logAnalyticsEvent(name: string, properties: any = {}) {
    try {
      const browserDetails = this.getBrowserDetails();
      this.segment.track(
        `${this.prefix}${name}`,
        {
          ...properties,
          ...browserDetails,
          email: get(speechifyMobileUser)?.email,
        },
        {
          protocols: {
            sourceId: ENV.segmentSourceId,
          },
        },
      );
    } catch {}
  }

  static identifyWithEmail(uid: string, email: string) {
    this.segment.identify(uid, {
      email,
    });
  }
}

authState.subscribe((state) => {
  if (state === AuthState.LOGGED_IN) {
    const { uid, email } = get(speechifyMobileUser);
    AnalyticsService.identifyWithEmail(uid, email);
  }
});
